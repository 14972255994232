@import '../../_variables'

.controls
    &__wrp
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 2em
        z-index: 3

        button
            width: 4em
            height: 4em

            svg
                font-size: 1.2em