@import '../../_variables'
    
.links
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    width: 210px
    margin: -30px auto 0 auto
    padding: 0 10px
    background: white
    border-radius: 8px
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)


    .link
        width: 60px
        height: 60px
        transition: all 0.1s ease-in-out
        background: white
        border-radius: 12px
        display: flex
        justify-content: center

        &:hover
            transform: scale(1.15)
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)

        img
            display: inline-block
            border-radius: 12px

    .blog img
        width: 50px
        height: 50px
        margin-top: 5px
