@import '../../_variables'
    
.background-wrapper
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    object-fit: cover
    object-position: center
    z-index: -1

    .background
        object-fit: cover
        object-position: center