@import './_variables'

#root
    --text: #{$gradient-stop-1}
    --box-shadow-color: #{$box-shadow-color}
    --timer-background-color: #{$white}
    --button-background-color: #{$white}
    --button-color: #{$gradient-stop-1}
    --background-gradient-stop-1: #{$gradient-stop-1}
    --background-gradient-stop-2: #{$gradient-stop-2}
    
    *
        user-select: none
        -webkit-user-select: none
        -moz-user-select: none

body
    margin: 0
    padding: 0
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif
    background: linear-gradient(90deg, var(--background-gradient-stop-1) 0%, var(--background-gradient-stop-2) 100%)
    min-height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    color: $white
    overflow: hidden

button
    appearance: none
    box-shadow: none
    background: transparent
    border: none
    outline: 0
    padding: 0.7em 0.9em
    width: 3em
    height: 3em
    margin: 0.5em
    border-radius: 50%
    background-color: var(--button-background-color)
    color: var(--button-color)
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
    transition: all 0.1s ease-in-out

    &:hover
        cursor: pointer
        transform: scale(1.1)

    &:disabled
        opacity: 0.6
        pointer-events: none
        box-shadow: none

        svg
            color: grey

    svg
        pointer-events: none
        vertical-align: -0.250em !important
