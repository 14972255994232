@import './_variables'

.timer
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-width: 350px
  background-color: var(--timer-background-color)
  color: var(--text)
  padding: 1em 2em 3em 2em
  border-radius: 16px
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)

  &__label
    z-index: 3
    color: $white
    font-weight: 500
    font-size: 20px
    margin-bottom: 15px
    line-height: 1
    position: relative

  &__time
    margin: 0
    margin-bottom: 0.3em
    font-size: 50px
    line-height: 1
    font-weight: 400
    position: relative
    z-index: 1
    color: $white

    &::after
      content: ''
      background: linear-gradient(90deg, var(--background-gradient-stop-1) 0%, var(--background-gradient-stop-2) 100%)
      width: 180px
      height: 180px
      border-radius: 50%
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: -1

  &__controllers
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

  &__maintitle
    font-size: 1.6em
    line-height: 2
    margin-top: 0.5em
    margin-bottom: 1.5em

@media screen and (max-width: 450px)
  .timer
    width: auto
    min-width: unset
    margin: 0 auto
    padding: 2em 1.5em